<template>
  <th-wrapper
    :title="$t('pages.settings.staff.staff_id_generator.title')"
    :info="$t('pages.settings.staff.staff_id_generator.instructions')"
  >
    <!-- Enable staff generator -->
    <div class="mb-4">
      <el-switch
        v-model="enableStaffIdGenerator"
        :active-text="enableStaffIdGeneratorSwitchLabel"
      >
        <el-input v-model="enableStaffIdGenerator" />
      </el-switch>
    </div>

    <!-- Auto id generator -->
    <auto-id-generator
      ref="auto-id-generator"
      :disabled="!enableStaffIdGenerator"
      :config-path="templatePath"
      :wanted-elements="elements"
    />
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'
import AutoIdGenerator from '../../../components/auto-id-generator'

export default {
  components: {
    AutoIdGenerator
  },
  data() {
    return {
      showMessage: true,
      templatePath: 'staff.staff_id_template',
      switchPath: 'staff.generate_staff_id',
      elements: [
        'full_date',
        'year',
        'month',
        'day',
        'country',
        'text',
        'sequence',
        'dash'
      ]
    }
  },
  computed: {
    enableStaffIdGenerator: {
      get() {
        return get(
          this.$store.state.Config.clientAccountConfiguration,
          this.switchPath
        )
      },
      set(isEnabled) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: this.switchPath,
          value: isEnabled
        })
      }
    },
    enableStaffIdGeneratorSwitchLabel() {
      return this.enableStaffIdGenerator
        ? this.$t('pages.settings.staff.staff_id_generator.switch.enabled')
        : this.$t('pages.settings.staff.staff_id_generator.switch.disabled')
    }
  },
  methods: {
    validate() {
      return this.$refs['auto-id-generator'].validate()
    }
  }
}
</script>
