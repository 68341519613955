<template>
  <section>
    <staff-id-generator ref="staff-id-generator" />
    <pin-length />
    <staff-number />
  </section>
</template>

<script>
import StaffIdGenerator from './components/staff-id-generator'
import PinLength from './components/pin-length'
import StaffNumber from './components/staff-number'

export default {
  components: {
    StaffIdGenerator,
    PinLength,
    StaffNumber
  },
  metaInfo() {
    return {
      title: this.$t('pages.settings.staff.title')
    }
  },
  methods: {
    async validate(cb) {
      const staffIdGeneratorValid = await this.$refs[
        'staff-id-generator'
      ].validate()

      cb(staffIdGeneratorValid)
    }
  }
}
</script>
