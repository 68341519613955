<template>
  <th-wrapper
    :title="$t('pages.settings.staff.ping_length.title')"
    :subtitle="$t('pages.settings.staff.ping_length.description')"
  >
    <el-input-number
      v-model="pinLength"
      :min="4"
      :max="10"
      @change="pinLength = $event"
    />
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  computed: {
    pinLength: {
      get() {
        return safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'staff.cashier_pin_gen_length',
          4
        )
      },
      set(newValue) {
        //Triggering pinLength value with 'change' event instead 'input' event,
        //since input event fires imminently (in el-input-number) and cause mutation of the state,
        //making the configuration dirty
        if (!newValue) return
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'staff.cashier_pin_gen_length',
          value: newValue
        })
      }
    }
  }
}
</script>
