<template>
  <th-wrapper
    :title="$t('pages.settings.staff.staff_number.title')"
    :subtitle="$t('pages.settings.staff.staff_number.description')"
  >
    <el-switch v-model="staffNumberEnabled" :active-text="switchMessage" />
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  computed: {
    staffNumberEnabled: {
      get() {
        return safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'staff.show_staff_number',
          false
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'staff.show_staff_number',
          value: newValue
        })
      }
    },
    switchMessage() {
      return this.staffNumberEnabled
        ? this.$t('common.interactions.switches.enabled_capital')
        : this.$t('common.interactions.switches.disabled_capital')
    }
  }
}
</script>
